import { Link, navigate } from "gatsby"
import { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { AuthContext } from "../../hooks/useAuth"
import { UpArrow } from "../Icons/UpArrow"
import Buttons from "../Buttons/external"
import { isBrowser } from "../../helpers"
import ErrorNotice from "../ErrorNotice/ErrorNotice"

const Mobile: React.FC<{email: string}> = ({email, ...remainingProps}) => {
  if (!email && isBrowser) {
    navigate("/check-registration")
  }
  const { state, dispatch }: any = useContext(AuthContext)
  const [isValidPassword, setIsValidPassword] = useState<boolean>(null)
  const [error, setError] = useState<string>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [login, setLogin] = useState({
    email: email?.toLowerCase(),
    code: "",
    password: "",
    confirmPassword: ""
  })
  
  useEffect(() => {
    if (!login.password.length || !login.confirmPassword.length) return
    setIsValidPassword(login.password === login.confirmPassword)
  }, [login.password, login.confirmPassword])

  const updateForm = (e: any) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit= async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsSubmitting(true)
    setError(null)
    if (Object.values(login).some(x => (x === null || x === ''))){
      setError("All fields are required.")
      setIsSubmitting(false)
    } else{
    if (isValidPassword === false) {
      setError("Passwords must match. Please try again.")
      setIsSubmitting(false)

    } else{

    try {
      const res = await fetch(`${process.env.GATSBY_API_ROOT_URL}/auth/users/set-password`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${state?.token}`
      },
      body: JSON.stringify({
        email: login.email,
        code: login.code,
        password: login.password
      })
    })
    .then(data => {
      if (data.status == 200) {
        setIsSubmitting(false)
        navigate("/login")
    } else {
      setIsSubmitting(false)
      setError("Invalid code. Please try again.")
    }})
  } catch (error) {
    setIsSubmitting(false)
    setError(
      "Something went wrong. Please try again. " + JSON.stringify(error)
    )
    console.error(error)
  }}
  }}


  return (
    <div css={[tw`visible lg:hidden relative z-50`]}{...remainingProps} >
        <div
          css={[tw`py-8 px-5 bg-red-300 flex flex-col justify-center w-full z-50 fixed bottom-0`]}
        >
          <h6 css={[tw`text-white text-center pb-8`]}>Enter new password</h6>
          <div
                className="input-container"
                css={[tw`relative mx-auto flex flex-col w-1/3 pb-8`]}
              >
                <input
                  className="input"
                  type="text"
                  placeholder="code"
                  value={login.code}
                  name="code"
                  onChange={updateForm}
                  required
                />
                <label htmlFor="code">Code</label>
          </div>
          <div
            className="input-container"
            css={[tw`relative mx-auto flex flex-col pb-8 w-72`]}
          >
            <input
              className="input"
              type="password"
              placeholder="Email Address"
              value={login.password}
              name="password"
              onChange={updateForm}
              required
            />
            <label htmlFor="password">Password</label>
          </div>
          <div
            className="input-container"
            css={[tw`relative mx-auto flex flex-col pb-8 w-72`]}
          >
           
            <input
            className="input"
            type="password"
            placeholder="confirm password"
            value={login.confirmPassword}
            name="confirmPassword"
            onChange={updateForm}
            required
          />
          <label htmlFor="confirmPassword">Enter Password Again</label>
          </div>
          {isValidPassword === false && (
            <p css={[tw`text-sm text-white mx-auto pb-5`]}>
              Passwords must match and be at least 8 characters.
            </p>
          )}
           {error && <ErrorNotice error={error} />}
           <Buttons
            value1={"Save"}
            value2={"Cancel"}
            action1={handleSubmit}
            link2={"/"}
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </div>
    </div>
  )
}

export default Mobile