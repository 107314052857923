import { Link } from "gatsby"
import tw from "twin.macro"
import { ButtonsProps } from "./buttons.d"

const Buttons: React.FC<ButtonsProps> = props => {
  return (
    <div css={[tw`flex flex-row justify-center gap-4`]}>
      <button
        onClick={props.disabled ? null : props.action1}
        type="button"
        value={props.value1}
        css={[
          tw`bg-red-700 hover:bg-red-500 hover:border-red-500 hover:scale-105 border border-2 border-red-700 text-white text-xl text-center px-10 whitespace-nowrap py-2 mt-5 rounded-[79px] relative`,

          props.disabled && tw`bg-gray-700 border border-2 text-white`,
        ]}
      >
        {props.value1}
        {props.loading && (
          <svg
            css={[
              tw`absolute animate-spin h-5 w-5 top-1/2 right-4 text-white inline-block`,
              `margin-top: -0.5em;`,
            ]}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              css={[tw`opacity-25`]}
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              css={[tw`opacity-75`]}
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
      </button>
      <Link
        to={props.link2}
      >
        <button  
        onClick={() => (document.body.style.overflow = "scroll")}
        type="button"
        css={[
          tw`bg-transparent border border-gray-100 hover:bg-red-500 hover:scale-105 hover:border-red-500 border-2 text-white text-xl text-center p-12 py-2 mt-5 rounded-[79px]`,
        ]}>{props.value2}</button>
    
      </Link>
    </div>
  )
}

export default Buttons