import { navigate } from "gatsby"
import { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { AuthContext } from "../../hooks/useAuth"
import Modal from "../Modal/Registration"
import ButtonGroup from "../Buttons/ButtonGroup"
import ErrorNotice from "../ErrorNotice/ErrorNotice"
import { isBrowser } from "../../helpers"

const Primary: React.FC<{ email: string }> = ({ email }) => {
  if (!email && isBrowser) {
    navigate("/check-registration")
  }
  const { state, dispatch }: any = useContext(AuthContext)
  const [isValidPassword, setIsValidPassword] = useState<boolean>(null)
  const [error, setError] = useState<string>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [login, setLogin] = useState({
    email: email?.toLowerCase(),
    code: "",
    password: "",
    confirmPassword: "",
  })

  useEffect(() => {
    if (!login.password.length || !login.confirmPassword.length) return
    setIsValidPassword(login.password === login.confirmPassword)
  }, [login.password, login.confirmPassword])

  const updateForm = (e: any) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsSubmitting(true)
    setError(null)

    if (Object.values(login).some(x => x === null || x === "")) {
      setError("All fields are required.")
      setIsSubmitting(false)
    } else {
      if (isValidPassword === false) {
        setError("Passwords must match. Please try again.")
        setIsSubmitting(false)
      } else {
        try {
          const res = await fetch(
            `${process.env.GATSBY_API_ROOT_URL}/auth/users/set-password`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${state?.token}`,
              },
              body: JSON.stringify({
                email: login.email,
                code: login.code,
                password: login.password,
              }),
            }
          ).then(data => {
            if (data.status == 200) {
              setIsSubmitting(false)
              navigate("/login")
            } else {
              setIsSubmitting(false)
              setError("Invalid code. Please try again.")
            }
          })
        } catch (error) {
          setIsSubmitting(false)
          setError(
            "Something went wrong. Please try again. " + JSON.stringify(error)
          )
          console.error(error)
        }
      }
    }
  }

  return (
    <Modal
      blue={true}
      open={true}
      modalCss={[tw`w-screen lg:max-w-7xl hidden lg:flex`]}
    >
      {" "}
      <div css={[tw`mx-auto px-36 py-16 bg-red-300 max-w-4xl `]}>
        <h5 css={[tw`text-white text-center pb-8 `]}>Enter new password</h5>
        <div
          className="input-container"
          css={[tw`relative flex flex-col w-1/3 lg:w-1/5`]}
        >
          <input
            className="input"
            type="text"
            placeholder="code"
            value={login.code}
            name="code"
            onChange={updateForm}
            required
          />
          <label htmlFor="code">Code</label>
        </div>
        <div
          className="input-container"
          css={[tw`relative mx-auto flex flex-col mt-5 mb-5`]}
        >
          <input
            className="input"
            type="password"
            placeholder="password"
            value={login.password}
            name="password"
            onChange={updateForm}
            required
          />
          <label htmlFor="password">Password</label>
        </div>
        <div
          className="input-container"
          css={[tw`relative mx-auto flex flex-col  mb-3 w-full`]}
        >
          <input
            className="input"
            type="password"
            placeholder="confirm password"
            value={login.confirmPassword}
            name="confirmPassword"
            onChange={updateForm}
            required
          />
          <label htmlFor="confirmPassword">Enter Password Again</label>
        </div>
        {isValidPassword === false && (
          <p css={[tw`text-sm text-white pb-5`]}>Passwords must match and be at least 8 characters.</p>
        )}
        {error && <ErrorNotice error={error} />}
        <ButtonGroup
          buttons={[
            {
              value: "Save",
              onClick: handleSubmit,
              dark: true,
              disabled: isSubmitting,
              loading: isSubmitting,
              eventName: "click_password_save",
              eventMessage: "store that the save event occurred",
            },
            {
              value: "Cancel",
              to: "/",
              secondary: true,
              eventName: "click_password_cancel",
              eventMessage: "store that the cancel event occurred",
            },
          ]}
        />
      </div>
    </Modal>
  )
}

export default Primary
