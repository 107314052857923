import { PageProps } from "gatsby"
import Password from "../components/Password"
import Seo from "../components/SEO"

const PasswordPage: React.FC<PageProps<any, any, { email: string }>> = ({location}) => {
  return (
    <div>
      <Seo title="Password" />

      <Password.Primary email={location?.state?.email} />
      <Password.Mobile email={location?.state?.email} />
      </div>
  )
}

export default PasswordPage